.blog .post,
.archive .post,
.search article,
.post-type-archive-activities article {
	margin-bottom: 3rem;
	.entry-title {
		margin-bottom: 1rem;
		margin-top: 0;
	}
	.entry-date {
		margin-bottom: 1rem;
	}
}

.single-post .alignleft, .single-post .alignnone {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.wp-caption-text {
	margin-top: .4rem;
}

.header--post {
	margin-bottom: 2rem;
}

// People
.header-people__image {
	border-radius: 100%;
	margin-right: 16px;
	height: 64px;
	width: 64px;
}

.header-people__item {
	margin-right: 32px;
    margin-bottom: 16px;
    max-width: calc(50% - 32px);
}


.header-people__title {
	font-size: 16px;
    line-height: 24px;
    font-weight: 700;
	line-height: 24px;
}

.header-people__role {
	line-height: 20px;
}

// Social hare

.social-share {
	background-color: #f7f7f7;
    padding: 6px 2px 6px 10px;
    border-radius: $border-radius-lg;
    display: inline-block;
}

.social-share__title {
	line-height: 20px;
}

// "Donate to us because" box below news, events
.box {
	background-color: $gray-lightest;
	margin-bottom: 2rem;
	margin-top: 2rem;
	@include media-breakpoint-down(md) {
		padding: 25px 20px;
	}
}

.box--newsletter,
.box--donate {
	padding: 25px;
	border-radius: $border-radius-lg;
}

.box--donate {
	border-top: 3px solid $brand-secondary;
}

.box__title {
	margin-bottom: 25px;
	margin-top: 0;
}

.entry-topics--single {
	margin-bottom: 1rem;
}

// Tabelid sisu sees horisontaalselt skrollitavaks

.entry-content {
	overflow-x: scroll;
	@include media-breakpoint-up(lg) {
		overflow-x: visible;
	}
}