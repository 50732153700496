// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  position: relative;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  &:after {
    content: none;
  }

  @include media-breakpoint-up(sm) {
    @include border-radius($navbar-border-radius);
  }
}

.navbar-page {

  .navbar-toggler-label {
    font-size: 1rem;
  }

  .nav-item {
    .nav-link {
      color: $gray;
      padding: 8px 10px;
      border-radius: 0;
      font-size: $font-size-sm;
      &:hover {
        color: $gray-dark;
      }
      .icon,
      .navbar-toggler .icon {
        opacity: .64;
      }
    }

    .nav-link,
    .navbar-toggler {
      &:hover {
        .icon {
          opacity: .88;
        }
      }
    }
  }
  .nav-item:not(:first-child) {
   .nav-link.active {
      color: $black;
      border-bottom: 2px solid $brand-primary;
      background-color: $gray-lighterer;
    }
  }
}

// Sticky

.sticky-wrapper {
  &.is-sticky {
    .navbar {
      @include elevation-02;
    }
  }
}


// Navbar alignment options
//
// Display the navbar across the entirety of the page or fixed it to the top or
// bottom of the page.

// A static, full width modifier with no rounded corners.
.navbar-full {
  z-index: $zindex-navbar;

  @include media-breakpoint-up(sm) {
    @include border-radius(0);
  }
}

// Fix the top/bottom navbars when screen real estate supports it
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: $zindex-navbar-fixed;

  // Undo the rounded corners
  @include media-breakpoint-up(sm) {
    @include border-radius(0);
  }
}

.navbar-fixed-top {
  top: 0;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-sticky-top {
  position: sticky;
  top: 0;
  z-index: $zindex-navbar-sticky;
  width: 100%;

  // Undo the rounded corners
  @include media-breakpoint-up(sm) {
    @include border-radius(0);
  }
}


//
// Logo
//

.navbar-brand {
  display: flex;
  height: 96px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 16px;
  @include media-breakpoint-down(sm) {
    width: 216px;
  }
}

// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// Bootstrap JavaScript plugin.

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar-toggler-label {
  margin-left: 8px;
}

.navbar-toggler {

}

.navbar-toggler-icon {

}

// Language nav
.navbar-lang.navbar-nav {

  flex-flow: row nowrap;
  justify-content: flex-end;
  flex-direction: row;

  .nav-item {
    float: left;
  }

  .nav-link {
    padding-top:    0;
    padding-bottom: 0;
    + .nav-link {
      margin-left: 16px;
    }
  }

  .nav-item + .nav-item {
    margin-left: 16px;
  }

  @include media-breakpoint-down(md) {
    .nav-link {
      padding-top:    0;
      padding-bottom: 0;
      + .nav-link {
        margin-left: 0px;
      }
    }

    .nav-item + .nav-item {
      margin-left: 0px;
    }
  }

  .nav-link {
    color: rgba(255,255,255,.64);
    padding: 10px 12px;
    @include media-breakpoint-down(sm) {
      padding: 11px 12px;
    }
    font-weight: 700;
    font-size: $font-size-sm;
    &:hover, &:focus {
      color: white;
    }
  }
  .menu-item-language-current {
    .nav-link {
      color: white;
    }
  }
}

// Navigation primary (new)

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  .nav-item {
    float: left;
  }

  .nav-link {
    display: block;

    + .nav-link {
      margin-left: 0;
    }
  }

  .nav-item + .nav-item {
    margin-left: 0;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-primary {
    margin-right: 16px;
  }
}


.navbar-primary, .navbar-primary-side {
  .nav-link {
    color: $black;

    padding: 12px;

    &:hover {
      background-color: $gray-lighter;
    }
    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
    &:active {
      background-color: darken($gray-lighter,10%);
    }

    padding: 12px;

    &.dropdown-toggle {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 16px;
      padding-right: 12px;

      &:after {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        vertical-align: middle;
        content: "";
        background-image: url("../images/icons-svg/keyboard_arrow_down_gray.svg");
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: center center;
        border: none;
      }

      &.dropdown-toggle--no-arrow {
        padding: 12px;
        &:after {
          content: none;
          width: 0;
          height: 0;
          background-image: none;
        }
      }
    }
  }
  .dropdown-item {
      font-size: 1.11112rem;
      padding: .3em 1.2em;
  }

  .btn {
    line-height: 1.5;
  }
}



@include media-breakpoint-down(lg) {

}

// Navbar toggleable
//
// Custom override for collapse plugin in navbar.

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;

  &.collapse.in {
  }

  &.collapse.in,&.collapsing {
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
    background: $gray-lightest;
    transition: all .1s ease-in;
  }
}

@include media-breakpoint-up(xs) {

  .navbar-expand-xs
  .navbar-toggleable-xs{
    .navbar-nav {
      flex-flow: row nowrap;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
}

@include media-breakpoint-up(md) {

  .navbar-expand-md,
  .navbar-toggleable-md {

    .navbar-nav {
      flex-flow: row nowrap;
      justify-content: flex-end;
      flex-direction: row;
    }

    .navbar-collapse {
      display: flex!important;
      flex-basis: auto;
      justify-content: flex-end;
    }

    .navbar-toggler {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {

  .navbar-expand-lg,
  .navbar-toggleable-lg {

    .navbar-nav {
      flex-flow: row nowrap;
      justify-content: flex-end;
      flex-direction: row;
      .nav-item {
          float: left;
        }

      .nav-link {

        + .nav-link {
          margin-left: 16px;
        }
      }

      .nav-item + .nav-item {
        margin-left: 16px;
      }
    }

    .navbar-collapse {
      display: flex!important;
      flex-basis: auto;
      justify-content: flex-end;
    }

    .navbar-toggler {
      display: none;
    }
  }

  // Lehesisene navi

  .navbar-toggleable-lg.navbar-page {
    .nav-link {
      + .nav-link {
        margin-left: 10px;
      }
    }

    .nav-item + .nav-item {
      margin-left: 10px;
    }
  }
}