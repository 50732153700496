html, body {
	max-width: 100%;
	max-height: 100%;
	overflow-x: hidden;
}

html {
	@include media-breakpoint-down(sm) {
		font-size: 16px;
	}
}

body {
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
}

.page:not(.home):not(.page-template-template-subtopic-page):not(.page-template-template-topic-page),
.post,
.blog,
.archive,
.error404,
.search {
	.wrap {
		margin-bottom: 4rem;
	}
}


img, object {
	max-width: 100%;
	height: auto;
}

.img-tag {
	position: absolute;
    left: 0;
    bottom: 0;
    background-color: $brand-warning;
    color: white;
    padding: 5px 15px;
    font-weight: 600;
}

.img-100 {
	width: 100px;
	height: 100px;
}

// LazySizes
.lazyload,
.lazyloading {
    opacity: 0;
}
.lazy-loaded {
    opacity: 1;
    transition: opacity 300ms;
}

h1, .title--h1 {
	font-size: $font-size-h1;
	font-family: $font-family-serif;
	margin-bottom: 2rem;
	margin-top: 2rem;
	@include media-breakpoint-down(sm) {
		font-size: $font-size-h2;
	}
}

h2, .title--h2 {
	font-size: $font-size-h2;
	font-family: $font-family-sans-serif;
	font-weight: 700;
	margin-bottom: 3rem;
}

h3, .title--h3 {
	font-size: $font-size-h3;
	font-family: $font-family-sans-serif;
	font-weight: 700;
	line-height: 1.3;
	margin-top: 4rem;
    margin-bottom: 2rem;
}

h4, .title--h4 {
	font-size: $font-size-h4;
	font-family: $font-family-sans-serif;
	font-weight: 700;
	margin-top: 3rem;
    margin-bottom: 1.5rem;
}

h5, .title--h5 {
	font-size: $font-size-h5;
	font-family: $font-family-sans-serif;
	font-weight: 500;
	margin-bottom: .2rem;
}

h6, .title--h6 {
	font-size: $font-size-h6;
	font-family: $font-family-sans-serif;
	font-weight: 700;
}

small {
	font-weight: 300;
}

hr {
	margin-top: 3rem;
	margin-bottom: 3rem;
	max-width: 33%;
	text-align: left;
}

.title--center, .text-center {
	text-align: center;
}

blockquote {
	color: $brand-secondary;
	font-size: $font-size-h3;
	font-family: $font-family-serif;
	padding: 1rem 2.3rem;
	line-height: 1.2em;
	display: block;
	position: relative;
	p {
		margin-bottom: 0;
	}
	&:before {
		content:"";
		position: absolute;
		top: 0;
		left: 0;
		width: 3px;
		height: 100%;
		background-color: $brand-secondary;
	}
}

.text-light {
	color: $white;
}

.text-bg {
	@include media-breakpoint-up(md) {
		position: relative;
		display: inline-block;
		&::before {
			content: "";
			width: 15px;
			height: 100%;
			position: absolute;
			left: -15px;
			top: 0;
		}
		&::after {
			content: "";
			width: 15px;
			height: 100%;
			position: absolute;
			right: -15px;
			top: 0;
		}
	}
}

.text-bg--dark {
	@include media-breakpoint-up(md) {
		&:not(.text-bg--children) {
			color: #fff;
			background-color: $gray-dark;
			&::before, &::after {
				background-color: $gray-dark;
				content: "";
			}
		}
		p, h1, h2, h3, h4, h5 {
			color: #fff;
			background-color: $gray-dark;
			&::before, &::after {
				background-color: $gray-dark;
				content: "";
			}
		}
	}
}

.text-bg--children {
	@include media-breakpoint-up(md) {
		p, h1, h2, h3, h4, h5 {
			position: relative;
			display: inline-block;
			&::before {
				content: "";
				width: 15px;
				height: 100%;
				position: absolute;
				left: -15px;
				top: 0;
			}
			&::after {
				content: "";
				width: 15px;
				height: 100%;
				position: absolute;
				right: -15px;
				top: 0;
			}
		}
	}
}

.text-bg--secondary {
	@include media-breakpoint-up(md) {
		color: #fff;
		background-color: $brand-secondary;
		&::before, &::after {
			background-color: $brand-secondary;
			content: "";
		}
	}
}

.visible-only-logged-in {
	display: none;
}

.logged-in .visible-only-logged-in {
	display: block;
}



// Display utility classes

.d-none {
	display: none;
}

.d-flex {
	display: flex;
}

.d-block {
	display: block;
}

// Flexbox classes

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

// Margin utility classes

.mt-0 {
	margin-top: 0;
}

.mt-1 {
	margin-top: .4rem;
}

.mt-2 {
	margin-top: 1rem;
}

.mt-3 {
	margin-top: 2rem;
}

.mt-4 {
	margin-top: 3rem;
}

.mt-5 {
	margin-top: 5rem;
}

.mb-0 {
	margin-bottom: 0;
}

.mb-1 {
	margin-bottom: .4rem;
}

.mb-2 {
	margin-bottom: 1rem;
}

.mb-3 {
	margin-bottom: 2rem;
}

.mb-4 {
	margin-bottom: 3rem;
}

.mb-5 {
	margin-bottom: 5rem;
}

.m-0 {
	margin: 0;
}

// Padding utility clases

.pt-2 {
	padding-top: 1rem;
}

.justify-content-center {
	justify-content: center !important;
}

// Images in posts

@include media-breakpoint-up(md) {
	.single-post {
		.alignleft, .alignnone {
			margin-left: 0;
		}

		.alignright {
			margin-right: 0;
		}
	}
}

// Links
a,
.read-more {
	font-weight: 500;
	color: $brand-primary;
	&:hover,&:active,&:focus {
		text-decoration: underline;
	}
}

.link--unstyled {
	&,&:hover,&:active,&:focus {
		font-weight: inherit;
		color: inherit;
		text-decoration: inherit;
	}
}

.link--unstyled-hover-underline:hover {
	text-decoration: underline;
}


.link--unstyled-hover-primary:hover {
	color: $brand-primary;
}

.link--unstyled-hover-warning:hover {
	color: $brand-warning;
}

.link--dark {
	&,&:hover,&:active,&:focus {
		color: $black;
	}
}

.link--white {
	&,&:hover,&:active,&:focus {
		color: #fff;
	}
}

.read-more {
	font-size: $font-size-sm;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}
// Colors

.color-primary {
	color: $brand-primary;
}

.color-warning {
	color: $brand-warning;
}

.color-danger {
	color: $brand-danger;
}

.bg-gray-lightest {
	background-color: $gray-lightest;
}

// Dates
.entry-date {
	display: inline-block;
	font-size: $font-size-sm;
	font-weight: 300;
}

// Topic tags
.topic-tag,
.section--news a.topic-tag {
	color: $gray-light;
	font-size: $font-size-xs;
	font-weight: 300;
	margin-right: .4rem;
}

// Images

.size-thumbnail {
	width: 150px;
	height: 150px;
}

.thumb-image {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  img {
    width: 100%;
    height: auto;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(lg) {
  	min-height: 202px;
  }
}

// Icons

.icon {
	width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    background-position: center;
}

.icon-20 {
    background-size: 20px;
}

.icon-16 {
    background-size: 16px;
}

.icon--black-link {
	opacity: .8;
	&:hover {
		opacity: .64;
	}
}

.icon--coloured-link {
	&:hover {
		opacity: .8;
	}
}

.list-inline-item:not(:last-child) {
	margin-right: 1rem;
}

.social-share__buttons {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 0;
}

.social-share__item {
	&, &:not(:last-child) {
		margin-right: .2rem;
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.fb_iframe_widget {
	height: 2rem;
}

.title--social-share {
	text-transform: uppercase;
	margin-right: 15px;
	color: #8b9094;
	font-weight: bold;
}

.list-inline-item--share {
	margin-right: 10px;
}


// Events
.events {
	margin-bottom: 2rem;
}

.events-list {
	margin-top: $grid-gutter-width*1.5;
}

.events-list__link {
	display: flex;
	margin-bottom: 8px;
	justify-content: center;
	align-items: center;
	border-radius: $border-radius-lg;
    background: #f7f7f7;
}

.section--light .events-list__link {
	background-color: #fff;
}

.events-list__date {
	background-color: $gray-lighter;
	border-radius: $border-radius-lg;
	flex: 0 0 100px;
	height: 100px;
	align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.events-list__day {
	margin-left: .3rem;
	font-size: $font-size-h2;
}
.events-list__month {
	font-size: $font-size-sm;
}

.events-list__text {
	flex: 1;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
}

.events-list__title {
	margin-top: 6px;
	margin-bottom: 4px;
}

.events-list__item:not(.events-list__item--single) {
	width: 100%;
	&:hover {
		.events-list__date {
			background-color: darken($gray-lighter,5%);
		}
		.events-list__text {
			text-decoration: underline;
		}
	}
}

// Equal height images
.equal-height-image {
	display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

// Tables

.table--activity {
	.row {
		padding-top: .7rem;
		padding-bottom: .7rem;
	}
	ul {
		margin-bottom: 0;
	}
}

.table__row-title {
	font-weight: 700;
}

// Pagination

// .pagination li a,
// .pagination li a:hover,
// .pagination li.active a,
// .pagination li.disabled {
// 	color: #fff;
// 	text-decoration:none;
// }

.pagination {
	width: 100%;
}

.pagination li {
	display: inline;
	a {
		display: inline-block;
		position: relative;
		color: $black;
		padding-left: .2rem;
		padding-right: .2rem;
		&:hover, &:active, &:focus {
			text-decoration: underline;
			// &:before {
			// 	content: "";
			// 	position: absolute;
			// 	left: 0;
			// 	bottom: -2px;
			// 	height: 2px;
			// 	width: 100%;
			// 	background-color: $brand-primary;
			// }
		}
	}
	&.active {
		a {
			font-weight: 300;
			// text-decoration: underline;
			// &:before {
			// 	content: "";
			// 	position: absolute;
			// 	left: 0;
			// 	bottom: -2px;
			// 	height: 2px;
			// 	width: 100%;
			// 	background-color: $brand-primary;
			// }
		}
	}
}

.pagination__list {
	display: block;
	text-align: center;
	padding-left: 0;
}

// .pagination li.pagination__prev,
// .pagination li.pagination__next {
// 	a {
// 		@extend .btn;
// 		@extend .btn-lg;
// 		@extend .btn-danger;
// 		font-family: $font-family-sans-serif;
// 		font-size: $font-size-base;
// 		&:hover, &:active, &:focus {
// 			text-decoration: none;
// 			&:before {
// 				content: none;
// 			}
// 		}
// 	}
// }

// .pagination__prev {
// 	margin-right: 1rem;
// }

// .pagination__next {
// 	margin-left: 1rem;
// }

// Google Map
.acf-map {
	width: 100%;
	height: 400px;
	border: none;
	margin: 20px 0;
}

/* Fixes potential theme css conflict with map */
.acf-map img {
   max-width: inherit !important;
}


// Alerts
.alert {
	border: none;
}

.alert-success {
    background-color: $brand-success;
    color: $black;
 }


// Dropdown

.dropdown-menu {
	border: none;
	border-radius: $border-radius;
	margin: 4px 0 0;
	@include elevation-08;
}

//reCAPTCHA

.grecaptcha-badge {
	opacity: .2;
	width: 70px !important;
	overflow: hidden !important;
	transition: all 0.3s ease !important;
	left: 4px !important;
	&:hover {
		opacity: 1;
		width: 256px !important;
	}
}

// Category filters

.filter--in-category {
	display: block;
}

.filter--not-in-category {
	display: none;
}