//
// Elevation styles
//

@mixin elevation-01 {
	box-shadow: 0 0 1px 1px rgba(0,0,0,.05), 0 1px 2px -2px rgba(0,0,0,.06), 0 1px 3px 0 rgba(0,0,0,.07);
}

@mixin elevation-02 {
	box-shadow: 0 0 1px 0 rgba(0,0,0,.16), 0 2px 2px 0 rgba(0,0,0,.05), 0 3px 1px -2px rgba(0,0,0,.06), 0 1px 5px 0 rgba(0,0,0,.1);
}

@mixin elevation-03 {
	box-shadow: 0 0 2px 0 rgba(0,0,0,.16), 0 3px 4px 0 rgba(0,0,0,.05), 0 3px 3px -2px rgba(0,0,0,.06), 0 1px 8px 0 rgba(0,0,0,.1);
}

@mixin elevation-08 {
	box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 8px 10px 1px rgba(0,0,0,.05), 0 3px 14px 2px rgba(0,0,0,.06), 0 5px 5px -3px rgba(0,0,0,.1);
}

@mixin elevation-16 {
	box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 16px 24px 2px rgba(0,0,0,.05), 0 6px 30px 5px rgba(0,0,0,.06), 0 8px 10px -5px rgba(0,0,0,.1);
}

@mixin elevation-24 {
	box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 24px 38px 3px rgba(0,0,0,.05), 0 9px 46px 8px rgba(0,0,0,.06), 0 11px 15px -7px rgba(0,0,0,.1);
}

.el-01 {
	@include elevation-01;
}
.el-02 {
	@include elevation-02;
}
.el-03 {
	@include elevation-03;
}
.el-08 {
	@include elevation-08;
}
.el-16 {
	@include elevation-16;
}
.el-24 {
	@include elevation-24;
}