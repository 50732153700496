// Header (ülemine riba + peamenüü)

// UUS valge
.banner-main {
	background: $white;
	box-shadow: 0px 2px 5px rgba(56, 56, 56, 0.08);
}

// Ülemine riba (Anneta ja sotsmeedia)
.banner-bar {
	background: $body-bg;
}

.banner-bar__container {
	justify-content: space-between;
	margin-left: 0;
	margin-right: 0;
}

.banner-bar__social {
	text-align: right;
    display: flex;
	flex-direction: row;
	align-items: center;
	.icon:not(:first-of-type) {
		margin-left: 16px;
		@include media-breakpoint-down(md) {
			margin-left: 0px;
		}
	}
}

.icon--social {
	height: 44px;
	width: 48px;
	@include media-breakpoint-down(md) {
		width: 44px;
    	background-size: 20px;
	}
	@include media-breakpoint-down(sm) {
		width: 40px;
	}
}

.banner-bar {
	background-color: $brand-primary;
	color: $white;
}

// Breadcrumbs
.breadcrumbs-bar {
	background-color: #FBFBFB;
}

.breadcrumbs-bar__content {
	// display: flex;
	// justify-content: space-between;
}

.breadcrumbs-bar__dropdown-toggle,
.breadcrumbs-bar__dropdown-toggle-link {
	max-width: 100%;
}

.breadcrumbs__pre-current {
	// flex: 1 0 auto;
}

.breadcrumbs-bar__dropdown {
	// flex: 1 1 auto;
}

#breadcrumbs {
	line-height: 44px;
	margin-bottom: 0;
	// display: flex;
	a, .breadcrumb_last {
		color: $gray-light;
		font-weight: 700;
	}
	a {
		font-weight: 700;
	}
	.breadcrumb_last {
		font-weight: 500;
	}
	.icon-keyboard_arrow_right {
	    width: 1.3em;
	    height: 1em;
	    background-size: cover;
	    display: inline-block;
	}
	.dropdown-toggle {
		display: inline-block;
		padding: 0 .5rem;
		color: $gray-dark;
		&:after {
			content: none;
		}
		&:hover, &:focus, &:active {
			background: $gray-lighter;
			text-decoration: none;
		}
		.icon-keyboard_arrow_down_gray {
			display: inline-block;
			width: 24px;
			height: 24px;
			background-size: 100%;
			vertical-align: middle;
			margin-left: .2rem;
		}
		.breadcrumbs-bar__dropdown-toggle-label {
			display: inline-block;
			overflow: hidden;
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    max-width: 500px;
		    vertical-align: bottom;
			@include media-breakpoint-down(lg) {
				max-width: 700px;
			}
			@include media-breakpoint-down(md) {
				max-width: 550px;
			}
			@include media-breakpoint-down(sm) {
				max-width: 300px;
			}
			@include media-breakpoint-down(xs) {
				max-width: 250px;
			}
		}
	}
	.breadcrumb-dropdown {
		top: 137%;
		box-shadow: 0 2px 3px rgba(0,0,0,.32);
		border: none;
		background-color: #fbfbfb;
		max-height: 300px;
    	overflow: scroll;
		a {
			color: $gray;
			font-weight: 500;
			&.breadcrumb-dropdown--active-link {
				color: $gray-dark;
				font-weight: 700;
			}
			&:hover {
				color: $gray-dark;
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.breadcrumbs__pre-current {
			display: none;
		}
	}
}

.breadcrumbs-pn {
	float: right;
	line-height: 44px;
	// align-self: end;
	// flex: 1 0 auto;
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.breadcrumbs-pn__label {
	vertical-align: middle;
	color: $black;
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.breadcrumbs-pn__icon {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-size: 100%;
	vertical-align: middle;
}

.breadcrumbs-pn__prev,
.breadcrumbs-pn__next {
	&, &:hover, &:active {
		color: $gray-dark;
	}
}

.breadcrumbs-pn__next {
	margin-left: 2rem;
	@include media-breakpoint-down(lg) {
		margin-left: .5rem;
	}
}

// Page Hero

.text--white-shadow {
	color: #fff;
	text-shadow: 0 2px 3px rgba(0,0,0,0.32);
}

.page-hero:not(.page-hero--no-bg) {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding: 0;
	height: calc(100vh - 500px);
	min-height: 300px;
	max-height: 600px;
	display: flex;
    justify-content: flex-start;
    align-items: flex-end;
	.page-hero__pre-title,
	.page-hero__title,
	.page-hero__separator,
	.page-hero__published,
	.page-hero__parent-title a {
		color: #fff;
		text-shadow: 0 2px 3px rgba(0,0,0,0.32);
	}
	.page-hero__content-wrapper {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.64+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.64) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.64) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.64) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a3000000',GradientType=0 ); /* IE6-9 */
	}
	@include media-breakpoint-down(lg) {
	    min-height: 300px;
    	max-height: 460px;
	}
	@include media-breakpoint-down(md) {
	    min-height: 240px;
	    max-height: 340px;
	}
	@include media-breakpoint-down(sm) {
	    min-height: 160px;
	}
}

.page-hero {
	padding-top: 3rem;
	.container {
		display: block;
   		width: 100%;
	}
}

.page-hero__content-wrapper {
	width: 100%;
}

.page-hero__pre-title {
	font-family: $font-family-serif;
	font-size: $font-size-lg;
	text-transform: uppercase;
}

.page-hero__title {
	margin-top: 0;
	margin-bottom: 1rem;
	text-align: center;
}

.page-hero__published {
	margin-bottom: 1rem;
	margin-top: 2rem;
	display: block;
	font-weight: $display1-weight;
}