.btn {
	font-weight: 700;
	border: none;
	border-radius: $border-radius;
}

.btn-md {
	padding: .612rem 1.4rem;
}

.btn-ghost, .btn-secondary {
	font-weight: 500;
}

.btn-ghost {
	background-color: transparent;
	color: $gray-dark;
	&:hover, &:active, &:focus {
		color: $black;
	}
	&:hover, &:focus {
		background-color: darken($gray-lighter,5%);
	}

	&:active {
		background-color: darken($gray-lighter,10%);
	}
}

.btn-ghost--alternative {
	background-color: transparent;
	color: $black;
	&:hover, &:active, &:focus {
		background-color: $gray-lighter;
		color: $black;
	}
	&:hover, &:focus {
		background-color: darken($gray-lighter,5%);
	}

	&:active {
		background-color: darken($gray-lighter,10%);
	}
}

.btn-ghost--on-dark {
	background-color: transparent;
	color: #fff;
	&:hover, &:active, &:focus {
		color: #fff;;
	}
	&:hover, &:focus {
		background-color: $gray-dark;
	}

	&:active {
		background-color: darken($gray-dark,5%);
	}
}

.btn-more {
	&[aria-expanded="true"]{
		.btn-more__icon {
			transform: rotate( -180deg );
			transition: transform 150ms ease;
		}
	}
}

.btn-more__icon {
	width: 24px;
    height: 24px;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3rem;
    margin-right: .1rem;
}

.btn-more:hover,
a:hover {
	.icon-keyboard_arrow_right,
	.icon-keyboard_arrow_right_gray,
	.icon-keyboard_arrow_right_blue,
	.icon-keyboard_arrow_right_white {
		margin-left: 0.4rem;
    	margin-right: 0
	}
}

.btn-more__label {
	vertical-align: middle;
}

.btn--top-anneta {
	border-radius: 0;
}