//*******
// Footer
//*******

.content-info {
	background-color: $gray-dark;
	color: #fff;
}

// Main footer on gray bg

.content-info__main {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.content-info__brand {
	float: left;
	margin-top: 36px;
	margin-right: 3rem;
	margin-bottom: 2rem;
	width: 329px;
	max-width:100%;
	height: 70px;
	background-size: 100%;
	& + p {
		clear: both;
	}
}

.content-info__about-navigation {
	margin-top: 30px;
	.nav-item {
		font-weight: 600;
	}
	.nav-link {
		&, &:hover,&:active,&:focus {
			color: #fff;
		}
		&:hover,&:active,&:focus {
			text-decoration: underline;
		}
	}
}

.content-info__action-container {
	padding: 30px 15px;
	background-color: #fff;
	color: $black;
	border-radius: 8px;
	@include media-breakpoint-down(md) {
		padding-bottom: 30px;
	    margin-bottom: 30px;
	}
	p {
		clear: both;
	}
}

.content-info__action-navigation {
	margin-bottom: 1rem;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	margin-bottom: 0;
	.nav-link {
		&, &:hover,&:active,&:focus {
			color: $black;
		}
		&:hover,&:active,&:focus {
			text-decoration: underline;
		}
	}
	.nav-item {
		font-weight: 300;
		&:first-child {
			.nav-link {
				color: #fff;
				font-weight: 700;
				padding: 8px 24px;
				background-color: $brand-secondary;
				border-radius: 4px;
				&:hover,&:focus {
					background-color: darken($brand-secondary,10%);
					text-decoration: none;
				}
				&:active {
					background-color: darken($brand-secondary,20%);
					text-decoration: none;
				}
			}
		}
	}
}

.content-info__social-list {
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	margin-bottom: 0;
}

.content-info hr {
	margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 75%;
}

// Subfooter on blue bg

.content-info__sub {
	background-color: $brand-primary;
	color: #fff;
	line-height: 44px;
}

.content-info__sub-links {
	@include media-breakpoint-up(md) {
		text-align: right;
	}
	.nav-link {
		&, &:hover, &:active, &:focus {
			color: #fff;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}