/*--------------------------------------------------------------
16.1 Galleries
--------------------------------------------------------------*/

.gallery-caption {
	color: #666;
	font-size: 13px;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.gallery-item {
	display: inline-block;
	text-align: left;
	vertical-align: top;
	margin: 0 0 1.5em;
	padding: 0 1em 0 0;
	width: 50%;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
	display: inline-block;
	max-width: 100%;
}

.gallery-item a img {
	display: block;
	-webkit-transition: -webkit-filter 0.2s ease-in;
	transition: -webkit-filter 0.2s ease-in;
	transition: filter 0.2s ease-in;
	transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.gallery-item a:hover img,
.gallery-item a:focus img {
	-webkit-filter: opacity(60%);
	filter: opacity(60%);
}

.gallery-caption {
	display: block;
	text-align: left;
	padding: 0 10px 0 0;
	margin-bottom: 0;
}

@media screen and (min-width: 30em) {

	/* Gallery Columns */

	.gallery-item {
		max-width: 25%;
	}

	.gallery-columns-1 .gallery-item {
		max-width: 100%;
	}

	.gallery-columns-2 .gallery-item {
		max-width: 50%;
	}

	.gallery-columns-3 .gallery-item {
		max-width: 33.33%;
	}

	.gallery-columns-4 .gallery-item {
		max-width: 25%;
	}
}

@media screen and (min-width: 48em) {

		/* Gallery Columns */

	.gallery-columns-5 .gallery-item {
		max-width: 20%;
	}

	.gallery-columns-6 .gallery-item {
		max-width: 16.66%;
	}

	.gallery-columns-7 .gallery-item {
		max-width: 14.28%;
	}

	.gallery-columns-8 .gallery-item {
		max-width: 12.5%;
	}

	.gallery-columns-9 .gallery-item {
		max-width: 11.11%;
	}
}

// Lightgallery overrides

.lg-outer .lg-has-youtube .lg-video-play {
	background: url(../images/youtube-play.png) no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
	background: url(../images/youtube-play.png) no-repeat scroll 0 -60px transparent;
}

.lg-outer .lg-item {
	background:url(../images/loading.gif) no-repeat scroll 50% transparent;
}

// Videos

.videos__item {
	margin-bottom: 16px;
	margin-top: 24px;
}

.thumb-image--video {
	position: relative;
	&::after {
	    content: " ";
	    background-image: url(../images/icons-svg/video-play.svg);
	    background-size: 74px;
	    position: absolute;
	    top: calc(50% - 37px);
	    left: calc(50% - 37px);
	    width: 74px;
	    height: 74px;
	}
}

.link--video:hover {
	.thumb-image--video::after {
		opacity: 0.8;
	}
}