// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-wrap: wrap;
}

.nav-link {
  display: block;
  padding: $nav-link-padding;
  border-radius: $border-radius;
  @include hover-focus {
    text-decoration: none;
  }

  // Disabled state lightens text and removes hover/tab effects
  &.disabled {
    color: $nav-disabled-link-color;
    cursor: $cursor-disabled;
  }
}

// Page nav

.nav--page {
	margin-top: 0;
	& .nav-item+.nav-item {
		margin-left: 0;
	}
}


//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus {
      border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
    }

    &.disabled {
      color: $nav-disabled-link-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-active-link-hover-color;
    background-color: $nav-tabs-active-link-hover-bg;
    border-color: $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-bg;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    border-radius: $border-radius;
  }
}


//
// Pills
//

.nav-pills {

   background-color: $gray-lightest;
   border-radius: $border-radius-lg;
   padding: 10px;
   @include elevation-01;

  .nav-link {
    border-radius: $nav-pills-border-radius;
    color: $gray;
    &:hover {
    	color: $gray-dark;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
  	@include elevation-02;
    color: $nav-pills-active-link-color;
    cursor: default;
    background-color: $nav-pills-active-link-bg;
    &:hover {
    	color: $nav-pills-active-link-color;
    }
  }
}


.section--light {
	.nav-pills {
		background-color: $white;
	}
}


//
// Justified variants
//

.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item {
    flex: 1 1 100%;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
