// Search form
.search-form {
  @extend .form-inline;
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
  .search-field {
    line-height: 1.5;
    margin-right: 8px;
  }
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit,
.wpcf7-submit {
  @extend .btn;
  @extend .btn-primary;
}

// Inputs
.wpcf7 p input,
input[type=text],
textarea,
input[type=number],
input[type=email],
input[type=tel],
#mc_embed_signup input {
	border-top: none;
	border-right: none;
	border-bottom: none;
	border-left: none;
  border-radius: $border-radius;
  border: 1px solid $gray-lighter;
	padding: .4rem .5rem;
  margin-bottom: 1px;
  max-width: 100%;
  font-weight: 300;
  &:focus {
    @include elevation-02;
  }
}

.form-inline .form-check-input, .search-form .form-check-input {
  margin-right: 4px;
  position: relative;
  margin-left: 0;
}

@include media-breakpoint-down(md) {

}

// Label formatting

label {
  max-width: 100%;
}

.wpcf7 p,
.wpcf7 label,
.wpcf7 legend {
  font-weight: 500;
  width: 100%;
  font-size: 1em;
}

.wpcf7-list-item-label {
  font-weight: 300;
}

.wpcf7-form-control-wrap {
  margin-top: 4px;
}

.wpcf7-checkbox {
  margin-top: 16px;
  display: block;
}

// Textarea

textarea {
  height: 160px;
}

// Input groups
.input-group input {
  border-radius: 0;
}

.input-group-addon {
  border: 1px solid $gray-lighter;
}

// Homepage newsletter signup

#subscribe-form {
  display: flex;
  flex-direction: row;
}

#subscribe-form input {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  width: 40%;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.newsletter-signup__subscribe {
  flex: 0;
  margin-left: 8px;
}

.newsletter-signup__email {
  min-width: 200px;
}


@include media-breakpoint-down(sm) {

  .newsletter-signup__subscribe {
    order: 1;
  }

  .newsletter-signup__archive {
    margin-top: 1rem;
    order: 2;
  }
}

// Universal alerts and validation errors

.alert {
  display: block;
  clear: both;
  border-radius: $border-radius;
  padding: $table-cell-padding;
}

.wpcf7-validation-errors {
  margin: inherit;
  border: none;
  margin-top: 20px;
  @extend .alert;
  @extend .alert-warning;
}

.wpcf7-response-output {
  margin: 0 0 30px;
}

// Checkboxes
span.wpcf7-list-item {
  display: inline-block;
  margin-right: 24px;
  margin-left: 0;
}