//******************
// HOME / Front Page 2018
//******************

.front-hero {
	padding-top: 2rem;
	background-repeat: no-repeat;
    background-size: 50%;
    background-position: -100px center;
}

.front-hero__text-container {
	margin: 2rem 0;
}

.front-hero__title {
	margin-bottom: 1.3rem;
}

.front-hero__text {

}

.front-hero__links-menu {
	display: flex;
	justify-content: space-around;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	.nav-link {
		color: $black;
		padding-left: .8rem;
		padding-right: .8rem;
		margin: 0;
		&:hover {
			&, .nav-link-label {
				color: $black;
				text-decoration: underline;
			}
		}
	}
	.nav-link-icon,
	.nav-link-label {
		display: inline-block;
		vertical-align: middle;
	}
	.nav-link-icon {
		height: 24px;
		width: 24px;
		background-size: 100%;
		margin-right: .5rem;
	}
	.nav-item+.nav-item, .nav-link+.nav-link {
		margin-left: 0;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
	    padding-top: .5rem;
	    padding-bottom: 2rem;
	    .nav-link {
	    	padding: .3rem 0;
	    }
	}
	@include media-breakpoint-down(sm) {
	    width: 100%;
	}
}

// Single event

.event-registration {
	margin-bottom: 2rem;
	background-color: $gray-lightest;
	padding: 20px;
	.form-check, input {
		margin: 0;
	    display: inline-block;
	    border: 1px solid rgba(0,0,0,.15);
	    &.active {
	    	&:after {
	    		border-bottom: 2px solid $brand-secondary;
			    bottom: 0;
	    		content: " ";
			    height: 2px;
			    left: 0;
			    position: absolute;
			    width: 100%;
	    	}
	    }
	    .form-check-label {
		    background-color: $gray-lighter;
		    padding: .6rem .5rem;
		    width: 100%;
		    &:hover {
		    	background-color: darken($gray-lighter,5%);
		    }
	    }
	}
	.wpcf7-submit {
		@extend .btn;
		@extend .btn-primary;
		@extend .btn-md;
	}
}

//******************
// Pages
//******************



.page-header {
	// padding-top: 1rem;
}

.page-header__featured-image {
	margin: 1rem 0 2rem;
}

.page-content h2 {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.page-template-template-subtopic-page-php .main,
.page-child .main,
.home .main {
	background-repeat: no-repeat;
    background-size: 50%;
    background-position: fixed;
    background-image: url('../images/watermark-xxl.svg');
}

.home .main {
	background-position: -100px -100px;
}

.page-template-template-subtopic-page-php .main,
.page-child .main {
	background-position: -100px 650px;
}


// Sections

.section--light {
	background-color: $gray-lightest;
}

.section--transparent {
	background-color: transparent;
}

.title--section
.section--content > .container > .row > div > h1:first-of-type,
.section--content > .container > .row > div > h2:first-of-type {
	margin-top: 2rem;
}

//******************
// Topic Page
//******************

.section,
.section--news,
.topic-events,
.topic-subpages, // deprecated
.section--cards,
.subtopic-members,
.materials,
.activities,
.front-events,
.front-newsletter {
	padding-top: 48px;
	padding-bottom: 72px;
}

.thumb-image,
.news__image-container,
.people__image-container,
.topic-materials__image-container {
	margin-bottom: 16px;
}

// Hero

.topic-hero {
	padding-top: 3rem;
	padding-bottom: 3rem;
	@include media-breakpoint-down(sm) {
	   padding-top: 2rem;
	}
}

.topic-hero__intro,
.drop-cap-for-first-child {
	p:first-child:first-letter {
	  float: left;
	  font-family: $font-family-serif;
	  font-size: 100px;
	  line-height: 70px;
	  padding-top: 4px;
	  padding-right: 8px;
	  padding-left: 3px;
	}
	div,
	div div,
	span,
	span span,
	div span,
	span div,
	div p a,
	div p a sup,
	a {
		p:first-child:first-letter {
			float: none;
			font-family: inherit;
			font-size: inherit;
			line-height: inherit;
			padding: 0;
		}
	}
}

.topic-hero__links {
	@include media-breakpoint-down(sm) {
	    margin-bottom: 2rem;
	}
}

.material-sub__main {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.topic-hero__image-container {
	img {
		width: 100%;
		height: auto;
	}
}
// Subpages

.topic-subpages, // deprecated
.section--cards {
	padding-top: 0;
}

// Events (and Newsletter)

.topic-events {
	padding-bottom: 3rem;
}

// Material authors

.authors-item__meta {
	margin-top: 2rem;
	display: flex;
    justify-content: flex-start;
    align-items: center;
}

.authors-item__thumb {
	width: 150px;
	height: 150px;
	margin-right: 1rem;
}


.authors-item__content {
	float: none;
	clear: both;
	display: block;
	margin-top: 2rem;
}

// Material next

.material-sub__next {
	height: 200px;
	width: 100%;
	background-color: $gray-lighter;
}

.material-sub__next-container {
	height: 100%;
	display: flex;
    justify-content: flex-start;
    align-items: center;
}

.material-sub__next-link {
	&, &:hover, &:active, &:focus {
		color: $black;
	}
}

// News / People

.section--news,
.sidebar--related {
	color: $black;
	a {
		color: $black;
	}
}

.news-item,
.people__item {
	margin-bottom: 1rem;
	margin-top: 1.3rem;
}

.news-item__date {
	display: block;
	margin-top: .5rem;
}

.news-item__topics {
	margin-top: .3rem;
	line-height: 1;
}

.materials__more,
.videos__more,
.more--center {
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 100%;
	text-align: center;
}

// Events and People

.section-side-people-events {
	padding-top: 3rem;
}

.people__extra {
	text-align: center;
    margin-top: 4rem;
}

// Materials

.materials {
	overflow: hidden;
}

.materials__item {
	margin-bottom: 16px;
	margin-top: 24px;
}

.title--material {
	font-weight: 500;
	font-size: $font-size-base;
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-align: center;
}

.material-main__container {
	padding-bottom: 4rem;
}

.material-main__intro,
.material-main__index,
.material-main__downloads {
	padding-top: 2rem;
}

.material-main__downloads-list {
	margin-top: 2rem;
}

.material__file-icon,
.material__file-title,
.material__file-type {
	vertical-align: middle;
}

// Index lists

.index-list {
	margin-bottom:0;
}
.index-list--2col {
	@include media-breakpoint-up(lg) {
		columns: 2;
		column-gap: 30px;
	}
}

.index-list__item {
	display: inline-block;
    width: 100%;
}

.index-list__link {
	display: block;
	position: relative;
	padding: 8px 40px 8px 16px;
	margin-bottom: 4px;
	border-radius: $border-radius;
	background-position: right 12px center;
	&:hover, &:focus {
		background-color: $gray-lightest;
		background-position: right 10px center;
		transition: .1s all ease-in;
		text-decoration: none;
		.index-list__title {
			color: $black;
		}
		.index-list__authors {
			color: $gray;
		}
		.index-list__icon {
			right: 13px;
		}
	}
}

.index-list__title {
	color: $black;
	font-size: $font-size-lg;
	padding-right: 35px;
    display: block;
}

.index-list__pre-title:lang(et) {
	text-transform: uppercase;
}

.index-list__pre-title,
.index-list__authors {
	font-weight: 300;
	display: block;
	max-width: calc(100% - 45px);
}

.index-list__pre-title {
	font-family: $font-family-serif;
	font-size: 13px;
	color: $brand-primary;
	text-transform: uppercase;
}

.index-list__authors {
	color: $gray-light;
	font-size: $font-size-sm;
}

.index-list__icon {
	background-size: 100%;
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -12px;
	width: 24px;
	height: 24px;
}

// Activities

.activities__item {
	padding: 30px;
	border-radius: 16px;
	position: relative;
	&:nth-child(2n+1) {
		background-color: $gray-lightest;
	}
}

.section--transparent {
	.activities__item {
		&:nth-child(2n+1) {
			background-color: $gray-lightest;
		}
	}
}

.section--light {
	.activities__item {
		&:nth-child(2n+1) {
			background-color: $white;
		}
	}
}

.activities__image {
	padding-bottom: 2rem;
}

// Past Activities

.past-activities__toggle {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

// *****************
// Materials single (Single Material)
// *****************

.material__file {
	margin-bottom: 1rem;
}

.material__file-icon {
	height: 32px;
	width: auto;
	margin-right: .5em;
	vertical-align: middle;
	float: left;
}

.material__file-name {
	vertical-align: middle;
}

// *********
// Donations
// *********

#donation-form-page--second,
#donation-form-page--third {
	display: none;
}


.donation-form__sum,
.donation-form__recurring-banks,
.donation-form__donation-type {
	display: flex;
	flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    .form-check, input {
		margin: 0;
	    display: inline-block;
	    border: 1px solid transparent;
	    &.active {
	    	&:after {
	    		border-bottom: 2px solid $brand-secondary;
			    bottom: 0;
	    		content: " ";
			    height: 2px;
			    left: 0;
			    position: absolute;
			    width: 100%;
	    	}
	    }
	    .form-check-label {
		    padding: .6rem .5rem;
		    width: 100%;
		    &:hover {
		    	background-color: darken($gray-lighterer,5%);
		    }
			&.form-check-label--custom-sum {
				padding: 5px 0.5rem;
			}
	    }

	}
}

.donation-form__donation-type {
	align-content: start;
	justify-content: center;
	.form-check {
		flex-grow: 1;
		&.active {
			font-weight: bold;
		}
		label {
			text-align: center;
		}
	}
	.form-check-input {
		display: none;
	}
}

.donation-form__recurring-banks,
.donation-form__donation-type {
	.form-check {
		.form-check-label {
			background-color: $gray-lighterer;
		}
	}
}

.donation-form__container {
	background-color: $gray-lightest;
	padding: 10px;
	.tab-pane {
		height: 82px;
		p {
			margin-bottom: 0;
		}
	}
}

.donation-form__sum {
	.form-check {
		.form-check-label {
			background-color: $gray-lighter;
		}
	}
}

.form-check--sum {
	flex-grow: 1;
    width: 33%;
	&.active .donation-form__sum-explanation {
		display: block;
	}
}

#donationSumCustom {
	width: 100%;
	background: transparent;
	padding: .4rem .2rem;
	&::placeholder {
		color: $black;
	}
	&:focus {
		background: $white;
	}
}

.form-check--personal-data {
	.form-control {
		width: 100%;
		padding-left: .5rem;
	}
	.input-group-addon {
	    width: 37px;
        padding: .22rem .25rem;
		background-color: transparent;
	    border: 1px solid transparent;
	    margin-top: 1px;
	}
}

.icon-question-circle--input-addon {
	width: 19px;
    height: 19px;
    display: inline-block;
    margin: 3px 0 4.4px;
    vertical-align: middle;
    background-position: center center;
}

.donation-form__bank-logo {
	max-width: 84px;
}

// Recurring donations modal

#recurringModal {
	@include media-breakpoint-up(md) {
		.modal-dialog {
			max-width: 720px;
			margin: 30px auto;
		}
		.modal-body {
			padding: 15px 60px;
		}
	}
}

// OLD – OBSOLOTE CODE

.page-template-template-donate {
	background-image: url('../images/donate_hero_bg_2.jpg');
	background-repeat: no-repeat;
	background-position: left -280px top 270px;
	.main {
		background-color: $white;
		position: relative;
		&:before,
		&:after {
			content: " ";
			position: absolute;
			top: 0;
			height: 100%;
			width: 15px;
			background-color: $white;
		}
		&:before {
			left: -15px;
		}
		&:after {
			right: -15px;
		}
	}
	blockquote {
		color: $black;
		font-size: $font-size-base;
		padding: 32px;
	    background-color: $gray-lightest;
	    width: 100%;
	    position: relative;
	    margin-right: 0;
	    margin-bottom: 2rem;
	    &:hover {
	    	background-color: darken($gray-lightest,1%);
	    }
	    &::after {
		    width: 0;
		    height: 0;
		    bottom: -35px;
		    left: 6px;
		    content: '';
		    background-color: transparent;
		    border-bottom: 25px solid transparent;
		    border-right: 25px solid transparent;
		    border-top: 25px solid $gray-lightest;
		    -webkit-transform: rotate(90deg);
		    transform: rotate(90deg);
		    position: absolute;
		}
	}
	.page-header h1 {
		color: $brand-secondary;
	}
}

// END OBSOLETE CODE

.icon-triangle-right {
	display: inline-block;
    height: 21px;
    width: 12px;
    vertical-align: middle;
    margin-right: 8px;
}

.donation--safe {
	line-height: 36px;
	text-align: right;
}

.icon-padlock {
	width: 13px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
}

// Donate 2023

.donate-hero {
	height: 47vh;
    max-height: 700px;
    min-height: 700px;
    padding-top: 80px !important;
    padding-bottom: 80px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include media-breakpoint-down(md) {
		height: 270vh;
		min-height: 1200px;
		max-height: 1200px;
	}

	@include media-breakpoint-down(sm) {
		height: auto;
		max-height: none;
		background-image: none !important;
		background: $gray-lightest;
	}
}

.shake {
	animation: shake 0.7s ease 0s 1 normal forwards;
}

@keyframes shake {
	0%,
	100% {
		transform: translateX(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateX(-10px);
	}

	20%,
	40%,
	60% {
		transform: translateX(10px);
	}

	80% {
		transform: translateX(8px);
	}

	90% {
		transform: translateX(-8px);
	}
}

.donate-hero--intro {
	h1 {
		line-height: 1.25;
	}
}

.section--video {
	padding-top: 120px;
	padding-bottom: 120px;
	@include media-breakpoint-down(sm) {
		background-image: none !important;
		background: $gray;
		box-shadow: none !important;
	}
}

.section--text {
	padding-top: 96px;
	padding-bottom: 96px;
}

.section--cards {
	padding-top: 96px;
	padding-bottom: 96px;
}

// Accordion

.card-body {
	padding: 1rem;
}

.text--next-to-icon {
	vertical-align: middle;
}

.donationBank {
	text-transform: uppercase;
}

.donate--left-right {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.section--faq {
	padding-top: 3rem;
	padding-bottom: 4rem;
	h3 {
		margin-top: 3rem;
	}
}

.title--faq {
	margin-bottom: 5rem;
}

.btn-pale {
	background-color: $gray-lighter;
	font-weight: 300;
}

.modal-title,
.float-left {
	float: left;
}

.close {
	float: right;
}

// Diversity agreement organizations page

.card-collapse-controller {
	vertical-align: middle;
	cursor: pointer;
}

.modal-body .card-block {
	margin-bottom: 1rem;
}

// Old
.table-striped--hidden-rows tbody tr:nth-of-type(odd) {
    background-color: transparent;
}

.table-striped--hidden-rows tbody tr:nth-of-type(4n+1) {
    background-color: rgba(0,0,0,.05);
}

.table-row--extra-information {
	display: none;
}

table th.table-collapse-controller {
	vertical-align: middle;
	cursor: pointer;
}

.icon-plus, .icon-minus {
	width: 16px;
    height: 16px;
    display: block;
    background-size: 16px;
    vertical-align: middle;
}

.table-row--extra-information {
	th, td {
		border-top: 1px solid transparent;
	}
}

.name {
	width: 33.333%;
}

//*******************
// WOOCOMMERCE PAGES
//*******************

.woocommerce-page .grecaptcha-badge {
	display: none!important;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
	@extend .btn;
	@extend .btn-primary;
}

.woocommerce .col2-set,
.woocommerce-page .col2-set {
	margin-bottom: 48px;
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
	background: $gray-lightest;
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
	background-color: $gray-lighter;
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
	border: 1em solid $gray-lighter;
	border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce ul.products li.product .price {
	color: $black;
    font-size: $font-size-h2;
}

.woocommerce ul.products li.product a {
	color: $black;
}

.woocommerce ul.products li.product .price {
	color: $black;
	font-size: $font-size-base;
	font-weight: 400;
}

.woocommerce a.added_to_cart {
	@extend .btn;
	@extend .btn-secondary;
}

.woocommerce a.remove {
	color: $brand-danger;
	&:hover {
		background: $brand-danger;
	}
}

.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover {
	background-color: lighten($brand-secondary, 20%);
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
	width: 140px;
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
	width: 100%;
}