// Inimesed

.events--sidebar {
	padding-bottom: 3rem;
}

.events-list{
	margin-top: $grid-gutter-width*1.5;
}

.sidebar-people {
	.attachment-thumbnail,
	.sidebar-people__text {
		display: block;
		vertical-align: middle;
		@include media-breakpoint-up(xxxl) {
			display: inline-block;
		}
	}

	.attachment-thumbnail {
		margin-right: $grid-gutter-width;
	}
}

.sidebar-people__list {
	margin-top: 1rem;
	margin-bottom: 0;
}

.sidebar-people__item:not(:last-child) {
	margin-bottom: $grid-gutter-width;
}

.sidebar-people__link {
	display: block;
}

.sidebar-people__email {
	font-size: $font-size-sm;
}

.sidebar-people__name {
	margin-top: .5rem;
	margin-bottom: 0;
}

// Menüü

.sidebar {
	margin-top: 2rem;
}

.sidebar-menu__list {
	& > li {
		&:not(.current_page_ancestor) {
			display: none;
		}
		font-size: $font-size-lg;
		font-weight: 700;
		&.current_page_ancestor a {
			display: block;
		    line-height: 44px;
		    padding-left: calc(44px + 1rem);
			color: $gray;
			border-radius: $border-radius;
			&:hover, &:active, &:focus {
				background-color: $gray-lightest;
				background-color: darken($gray-lightest,2%);
				color: $gray-dark;
				text-decoration: none;
			}
			position: relative;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top:0;
				height: 44px;
				width: 44px;
				background-color: $gray-lighter;
				background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23383838%22%3E%3Cpath%20d%3D%22M15.422%2016.078l-1.406%201.406-6-6%206-6%201.406%201.406-4.594%204.594z%22/%3E%3C/svg%3E');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 2rem;
				border-radius: $border-radius;
			}
		}
		&.current_page_item > a,
		&.active > a {
			font-weight: 700;
		}
		& > .children {
			padding-left: 0;
			margin-top: 1rem;
			li {
				list-style: none;
				a {
					display: block;
				    position: relative;
				    padding: 8px 40px 8px 16px;
				    margin-bottom: 4px;
					background-color: transparent;
					background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%2300AEEF%22%3E%3Cpath%20d%3D%22M8.578%2016.36l4.594-4.595L8.578%207.17l1.406-1.405%206%206-6%206z%22/%3E%3C/svg%3E);
	    			background-repeat: no-repeat;
					background-position: right 12px center;
				    border-radius: $border-radius;
					font-weight: 300;
					line-height: 1.4;
					color: $black;
					&:hover, &:active, &:focus {
						color: #000;
						background-color: $gray-lightest;
						background-position: right 10px center;
						transition: .1s all ease-in;
						text-decoration: none;
					}
					&:before {
						content: none;
					}
				}
				&.current_page_item > a,
				&.active > a,
				&.current_page_parent > a {
					font-weight: 700;
				}
				& > .children {
					display: none;
				}
				&.current_page_item > .children,
				&.current_page_parent > .children {
					display: block;
				    margin-left: 3rem;
				    a {
				    	padding-left: 0;
				    }
				    .children {
				    	padding-left: 0;
				    }
				}
			}

			& > .page_item_has_children {
				.children {
					display: none;
				}
			}
		}
	}
}

.sidebar-menu__list--whole-tree {
	& > li {
		&, &:not(.current_page_ancestor) {
			display: block;
		}
		& > .children {
			& > li {
				&.current_page_item > a,
				&.active > a {
					font-weight: 700;
				}
				& > .children {
					display: block;
					& > li {
						&.current_page_item > a,
						&.active > a {
							font-weight: 700;
						}
					}
				}
			}
			& > .page_item_has_children {
				.children {
					display: block;
				}
			}
		}
	}
}

.sidebar-menu__notice {
	font-weight: 300;
	padding-top: 1rem;
}

// Related items in sidebar

.sidebar--related {
	border-radius: 8px;
	padding: $grid-gutter-width/2;
	@include media-breakpoint-up(sm) {
		margin-top: 360px;
	}
	.news-item {
		margin-bottom: 2rem;
		margin-top: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
}